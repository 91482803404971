div:not(.minimal-cards) {
	.card {
		border: none;
		background-color: #fff;
		.card-caption {
			padding: 1.5rem;
			.card-title {
				font-size: $h5-font-size;
			}
			.card-subtitle {
				font-size: $h6-font-size;
				font-family: $font-family-primary;
				font-weight: 200;
			}
			.card-description-content * {
				font-size: 16px;
			}
		}
		.card-buttons {
			padding: 0.5rem 1.5rem;
		}
		div.card-image,
		.card-image-link {
			&::after {
				content: "";
				position: absolute;
				bottom: -1px;
				left: 0;
				width: 100%;
				height: 6vh;
				background: url("/images/Middel 5.svg");
				background-repeat: no-repeat;
				background-size: 105%;
				background-position: bottom;
			}
		}
	}
}

div.minimal-cards {
	.card {
		position: relative;
		border: 0;
		box-shadow: none;
		.card-title {
			color: $white !important;
			font-weight: 200 !important;
			font-size: $h6-font-size !important;
		}
		.card-body {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			border: 0;
			.card-caption {
				display: flex;
				flex-flow: column;
				align-items: center;
				justify-content: flex-end;
				padding-bottom: 3vh;
				* {
					z-index: 2;
				}
				&::before {
					content: "";
					background: linear-gradient(
						rgba(white, 0),
						rgba(black, 0.3)
					);
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 60%;
					z-index: 1;
				}
			}
		}
		.card-subtitle,
		.card-description,
		.card-buttons {
			display: none !important;
		}
		div.card-image {
			&::after {
				content: "";
				position: absolute;
				bottom: -1px;
				left: 0;
				width: 100%;
				height: 6vh;
				background: url("/images/Middel 5.svg");
				background-repeat: no-repeat;
				background-size: 105%;
				background-position: bottom;
				z-index: 3;
			}
			&::before {
				content: "";
				position: absolute;
				top: -1px;
				left: 0;
				width: 100%;
				height: 6vh;
				background: url("/images/Middel 5.svg");
				background-repeat: no-repeat;
				background-size: 105%;
				background-position: bottom;
				transform: scaleY(-1) scaleX(-1);
				z-index: 3;
			}
		}
	}
}
.content-section.omgeving {
	.card-caption {
		padding-bottom: 0;
	}
	div.card-image {
		&::after {
			display: none;
		}
	}
}

.grid-items {
	.item {
		&:nth-child(1),
		&:nth-child(2) {
			div.card-image {
				&::before {
					display: none !important;
				}
			}
		}
		&:nth-last-child(-n + 2) {
			div.card-image {
				&::after {
					display: none !important;
				}
			}
		}
	}
}

section.omgeving {
	div.minimal-cards {
		div.card-image {
			&::after,
			&::before {
				display: none !important;
			}
		}
	}
}
