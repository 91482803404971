// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

// lead-section
// =========================================================================
&.home .lead-section {
	.container-one-column {
		.container-holder {
			max-width: 750px;
			margin: 0 auto;
			text-align: center;
		}

		&.left-align {
			.container-holder {
				max-width: 100%;
				text-align: left;

				.column {
					padding-left: 0;
				}
			}
		}
	}
}

.lead-section {
	padding-top: 6vh;

	.container-one-column {
		.container-holder {
			max-width: 100%;
			margin: 0 0;
			text-align: left;
			.column {
				padding-left: 0;
			}
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-start;
			@extend .list-unstyled;

			li {
				margin: 0 15px 0 0;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 3vh 0;

	// intro/outro
	.intro,
	.outro {
		max-width: 750px;

		.container-holder {
			text-align: center;
		}
	}

	.intro {
		.container-holder {
			margin-bottom: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.outro {
		.container-holder {
			margin-top: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	&.omgeving {
		margin: 6vh 0 0 0;
		background: url("/images/tree-left.svg");
		background-repeat: no-repeat;
		background-position-x: -4rem;
		background-position-y: 2rem;
		@include media-breakpoint-down(lg) {
			background-size: 150px !important;
		}
	}

	&.faciliteiten {
		background: url("/images/tree-left.svg");
		background-repeat: no-repeat;
		background-position-x: calc(100% + 4rem);
		background-position-y: 2rem;
		background-size: 370px;
		&:nth-child(2) {
			background-position-x: -4rem;
			background-position-y: 2rem;
		}
		.item {
			max-width: 50% !important;
			flex-basis: 50% !important;
			@include media-breakpoint-down(md) {
				max-width: 100% !important;
				flex-basis: 100% !important;
			}
		}
		@include media-breakpoint-down(lg) {
			background-size: 150px !important;
		}
	}
	&.special {
		@include media-breakpoint-up(lg) {
			&::before {
				content: "";
				height: 6vh;
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background: url("/images/top.svg");
				background-size: 100%;
				background-repeat: no-repeat;
				z-index: 990;
			}
			&::after {
				content: "";
				height: 6vh;
				width: 100%;
				position: absolute;
				bottom: 0;
				left: 0;
				background: url("/images/bottom.svg");
				background-size: 100%;
				background-repeat: no-repeat;
				background-position: bottom;
				z-index: 990;
			}

			position: relative;
			background: linear-gradient(90deg, $blue 50%, $body-bg 50%);
			overflow: hidden;
			.column {
				padding: 18vh 0;
				&.text {
					max-width: 40%;
					flex-basis: 40%;
				}
				&.image {
					max-width: 60%;
					flex-basis: 60%;
				}
				@include media-breakpoint-down(md) {
					&.text,
					&.images {
						max-width: 100%;
						flex-basis: 100%;
						padding-left: 1rem;
						padding-right: 1rem;
					}
				}
			}
			.text {
				background: $blue;
				color: $white;
				position: relative;
				.title h2 {
					color: $white !important;
				}
				&::before {
					content: "";
					position: absolute;
					right: -7rem;
					top: 0;
					width: 7rem;
					height: calc(100%);
					background: url("/images/swirl-text-left.svg");
					background-repeat: no-repeat;
					background-size: 100%;
					background-position: left;
					z-index: 700;
				}
			}
			.image {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 60vw;
					max-width: unset !important;
					object-fit: cover !important;
					z-index: 600;
				}
			}

			&.reverse {
				background: linear-gradient(90deg, $body-bg 51%, $blue 49%);

				.text {
					padding-left: 1rem;
					&::before {
						right: unset;
						left: -4.9rem;
						background: url("/images/swirl-text-right.svg");
						background-size: cover;
					}
				}

				.image {
					img {
						left: unset;
						right: 0;
					}
				}

				&::before {
					transform: scaleX(-1);
				}
				&::after {
					transform: scaleX(-1);
				}
				.container {
					&::before {
						content: "";
						position: absolute;
						top: 0;
						right: 0;
						width: 100%;
						height: 8vh;
						background: url("/images/top-text-left.svg");
						background-repeat: no-repeat;
						background-size: 50%;
						background-position: top right;
						z-index: 950;
						transform: none !important;
					}
					&::after {
						background: url("/images/bottom-text-right.svg");
						background-repeat: no-repeat;
						background-size: 50%;
						background-position: bottom right;
					}
				}
			}
		}

		@media (max-width: 991px) {
			background: $blue;
			padding: 6vh 0;
			padding-bottom: 12vh;
			&::before {
				height: 6vh !important;
				background: url("/images/top.svg") !important;
				background-repeat: no-repeat !important;
			}
			&::after {
				background: url("/images/bottom.svg") !important;
				background-size: 100% !important;
				background-repeat: no-repeat !important;
			}
			.column {
				max-width: 100%;
				flex-basis: 100%;
				&.text {
					width: 100%;
					flex-basis: 100% !important;
					max-width: 100% !important;
					p,
					.title > * {
						color: #fff;
					}
					&::after {
					}
				}
			}
		}
		@include media-breakpoint-down(sm) {
			position: relative;
			background: $blue;
			margin-bottom: 0;
			padding: 0;
			&::before {
				content: "";
				position: absolute;
				top: -1px;
				left: 0;
				background: url("/images/Middel 6.svg") !important;
				background-repeat: no-repeat !important;
				background-size: 100% !important;
				width: 100vw !important;
				height: 4vh !important;
				z-index: 990 !important;
			}
			&::after {
				content: "";
				position: absolute !important;
				bottom: -2px !important;
				left: 0 !important;
				background: url("/images/bottom-image-left.svg") !important;
				background-repeat: no-repeat !important;
				background-size: 100% !important;
				background-position: bottom !important;
				width: 100% !important;
				height: 5vh !important;
				z-index: 990 !important;
			}

			.container-holder {
				flex-flow: column !important;
				.column:nth-child(1) {
					position: relative;
					&::after {
						content: "";
						position: absolute;
						bottom: -6vh;
						left: 0;
						background: url("/images/Middel 10.svg");
						background-repeat: no-repeat;
						background-size: 100%;
						width: 100%;
						height: 5vh;
						z-index: 990;
					}
				}
			}
			.text {
				margin-top: 6vh;
				padding: 1.5rem;
				color: $white;
				max-width: 90%;
				.title * {
					color: $white !important;
				}
			}
			.image {
				padding: 0;

				img {
					min-height: 45vh;
					object-fit: cover !important;
				}
			}
			&.reverse {
				.container-holder {
					flex-flow: column-reverse;
					.column:nth-child(1) {
						position: relative;
						&::after {
							bottom: 0;
							transform: scaleY(-1);
						}
					}
				}
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin: 6vh 0;
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}
