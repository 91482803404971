// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
	.owl-caption-holder,
	.owl-nav,
	.owl-dots {
		display: none;
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 45vh;
	min-height: 540px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: 70vh;
	}
}

section.eyecatcher {
	position: relative;
	&::after {
		content: "";
		position: absolute;
		bottom: -0.1rem;
		left: 0;
		height: 6vh;
		width: 100%;
		background-image: url("/images/Middel 3.svg");
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: bottom;
		z-index: 995;
		@include media-breakpoint-down(lg) {
		}
	}
}
