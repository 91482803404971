// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $blue;
	transition: 0.5s;

	&.sticky {
	}

	> [class*="container-"] {
		.container-holder {
			> .column {
				max-height: 70px;
				@extend .align-items-center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
					align-items: flex-start !important;
					justify-content: space-evenly;
					padding-top: 0.5rem;
					padding-left: 0;
					padding-right: 0;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		i {
			color: $white;
		}
	}

	// logo
	.logo {
		width: 223px;
		margin: 1rem;
		transform: translateY(40px);
		z-index: 1000;

		a {
			img {
				@extend .w-100;
			}
		}
		@include media-breakpoint-down(lg) {
			width: 140px;
			transform: translate(0, -20%);
		}
	}

	// menu

	.menu {
		.nav-item {
			.nav-link {
				color: $white;
				font-family: $font-family-secondary;
				&:hover {
					color: $green;
				}
			}
		}
		@include media-breakpoint-down(md) {
			order: 1;
			background: $blue;
			width: 100vw !important;
			flex-basis: 100% !important;
			transform: translateY(-3rem);
			padding-top: 3rem !important;
			margin-left: 0;
			padding: 1rem;
			li.nav-item a {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
			.dropdown-menu.show {
				position: static !important;
				transform: none !important;
				background: $blue;
				border: none;
				li {
					display: flex;
					align-items: center;
					gap: 0.5rem;
					font-weight: 100;
					font-size: small;
					&::before {
						content: "- ";
						float: left;
						color: $white;
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			.navbar-nav {
				gap: 1.2rem;
			}
			.dropdown-menu.show {
				border: 0;
				background: $blue;
				text-align: center;
				&::after,
				&::before {
					display: none;
				}
				.nav-item {
					&:hover {
						a {
							color: $green;
						}
					}
				}
			}
		}
	}

	// header btn
	div.header-btn {
		margin-left: auto;
		margin-right: 0;
		min-width: max-content;
		display: block;
		z-index: 1000;

		a {
			@extend .btn, .btn-primary;
			max-height: 36px;
			padding: 0.25rem 1.5rem !important;
			&::after {
				display: none !important;
			}
			@include media-breakpoint-down(lg) {
				max-width: 115px;
				height: 26px !important;
				font-size: x-small;
				padding: 0.25rem 0.5rem !important;
			}
		}
		@include media-breakpoint-down(lg) {
			margin-left: 0;
		}
	}

	// site-switcher
	.site-switcher {
		margin-right: 0;
		margin-left: 0.55rem;
		display: block;
		min-width: max-content;
		z-index: 1000;
		a.dropdown-toggle {
			display: flex !important;
			align-items: center;
			justify-content: center;
			gap: 0.35rem;
			max-height: 36px;
			background: $blue-light;
			height: 36px;
			width: 66px;
			border-radius: 40px !important;
			padding: 0.5rem !important;
			z-index: 1000;
			&:hover {
				border-color: $blue-light !important;
				&::after {
					color: $white;
				}
			}
			&::after {
				content: "\f078";
				font-family: $font-awesome;
				border: 0;
			}
			@include media-breakpoint-down(lg) {
				font-size: x-small;
				max-height: 26px;
				max-width: 35px;
				&::after {
					display: none;
				}
			}
		}
		.dropdown-menu.show {
			background: $blue-light;
			transform: translateY(10px) !important;
			width: 100%;
			border: none;
			border-radius: 0 0 20px 20px;
			padding-top: 24px !important;
			padding-bottom: 18px !important;
			text-align: center;
			box-shadow: none;
			z-index: -1;
		}
		@include media-breakpoint-down(lg) {
			max-height: 26px;
		}
	}
	&::after {
		content: "";
		position: absolute;
		bottom: -5vh;
		height: 6vh;
		width: 100%;
		background: url("/images/Middel 1.svg");
		background-repeat: no-repeat;
		background-size: 100%;
		z-index: -1;
		@include media-breakpoint-down(lg) {
			background-size: 100%;
			height: 2rem;
			bottom: -1.75rem;
		}
	}
}

// home
&.home {
	.header {
		@include media-breakpoint-up(xl) {
			position: fixed;
		}
	}
}
