// footer
.footer {
	.column {
		text-align: center;
		display: flex;
		justify-content: center;
	}
	.footer-intro {
		background: url("/images/tree-left.svg");
		background-repeat: no-repeat;
		background-position-x: calc(100% + 10rem);
		background-position-y: 2rem;
		z-index: 999;
		p {
			font-size: 16px !important;
		}
		h6,
		h5,
		h4,
		h3,
		h2,
		h1 {
			font-size: 40px !important;
		}
		.column.one {
			@include media-breakpoint-down(lg) {
				margin-top: 6vh;
			}
		}
		.column.two {
			min-height: 65vh;
			&::after {
				content: url("/images/footer-mascotte.svg");
				transform: translateY(19%);
				z-index: 999;
			}
			@include media-breakpoint-down(lg) {
				background: url("/images/footer-mascotte.svg");
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center bottom;
				transform: translateY(20%);
				z-index: 998;
				pointer-events: none;

				&::after {
					display: none;
				}
			}
		}
		.mascotte img {
			display: none;
		}
		div.footer-btn {
			display: flex;
			flex-flow: column;
			justify-content: center;
			align-items: center;
			margin-bottom: 1rem;
			ul.footer-btn {
				display: flex;
				flex-flow: column;
				align-items: center;
				gap: 1rem;
				a {
					@extend .btn;
					background-color: $blue !important;
					color: $white;
					display: flex;
					align-items: center;
					gap: 0.45rem;
					max-width: max-content;
					i {
						font-size: 18px;
						transition: 0.35s ease;
					}
					&:hover {
						background: $green !important;
						color: $white !important;
						i {
							transform: scaleX(-1);
							transition: 0.35s ease;
						}
					}
					&::after {
						display: none !important;
					}
				}
			}
		}
		@include media-breakpoint-down(lg) {
			background-size: 250px !important;
			background-position-x: calc(100% + 155px);
		}
	}
	.footer-social,
	.footer-socket {
		background: $blue;
		* {
			color: $white !important;
			&:hover {
				color: $green !important;
			}
		}
	}
	section.footer-social {
		position: relative;
		padding: 3vh 0;
		ul.footer-social {
			display: flex;
			justify-content: center;
			gap: 1.5rem;
			padding-top: 1rem;
			z-index: 999;
			i {
				font-size: 36px;
				transition: 0.35s;
				&:hover {
					transform: scale(1.15);
					transition: 0.35s;
				}
			}
			.list-item-title {
				display: none;
			}
		}
		&::before {
			content: "";
			position: absolute;
			top: -4vh;
			height: 4vh;
			width: 100%;
			background-image: url("/images/Middel 2.svg");
			background-repeat: no-repeat;
			background-size: cover;
			z-index: -1;
		}
	}
	.footer-socket {
		padding: 1rem;
		ul {
			width: 100%;
			gap: 1rem;
			justify-content: center;
			a {
				text-decoration: none;
			}
		}
	}
}
