// custom-list
.custom-list {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 0;

		&::before {
			content: "\f00c";
			font-family: $font-awesome;
			margin-right: 0.25rem;
			font-weight: 900;
			color: $primary;
		}
	}
}

// custom-list-extra
.custom-list-extra {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 0;

		&::before {
			content: "\f058";
			font-family: $font-awesome;
			font-weight: 400;
			margin-right: 0.25rem;
			color: $primary;
		}
	}
}
