// mini-sab
.mini-sab {
	margin-top: 20px;

	.container {
		z-index: 996;
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			z-index: 100;
			position: relative;
			margin: 0;
			padding: 5px 0 20px 0;
			border-radius: 80px;
			background: $blue;
			box-shadow: 0 3px 6px rgba($black, 0.15);
			color: #fff;

			// below eyecatcher
			@include media-breakpoint-up(lg) {
				padding-left: 30px;
				padding-right: 30px;
			}

			@include media-breakpoint-down(lg) {
				border-radius: 24px;
				position: relative;
			}

			&::before {
				content: url("/images/header-mascotte.svg");
				position: absolute;
				right: 0;
				top: 0;
				transform: translateY(-477px);
				min-height: 550px;
				min-width: 325px;
				max-height: 550px;
				max-width: 325px;
				display: flex;
				align-items: flex-end;
				z-index: 990;

				@include media-breakpoint-down(lg) {
				min-width: 125px;
				transform: scale(0.5) translate(20%, -754px);
				}
			}
		}

		// heading
		.heading {
			margin-bottom: 5px;
			font-size: 24px;
			text-align: center;
			font-family: $font-family-secondary;
		}
	}
}
